import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";

export default function AddFieldButton({ openDrawer }) {
  return (
    <Grid
      item
      onClick={() => openDrawer(true)}
      className="form-add-field-button"
    >
      <AddIcon />
    </Grid>
  );
}
