import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function FromScratch({closeDialog}) {
  const history = useHistory();
  const onClick = () => {
    closeDialog();
    history.push("/build")
  }
  return (
    <div className="item-content">
      <Grid container style={{height:"100%"}} justifyContent="center" alignContent="center" direction="column">
        <Grid item onClick={onClick}>
          <AddIcon
            style={{ fontSize: "50px", cursor: "pointer", color: "inherit" }}
            fontSize={"inherit"}
          />
        </Grid>
      </Grid>
    </div>
  );
}
