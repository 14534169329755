import { Grid } from "@mui/material";
import React from "react";

export default function FromTemplate() {
  return (
    <div className="item-content">
      <Grid
        container
        style={{ height: "100%" }}
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        <Grid item>
          <img
            width="100%"
            src={`${
              process.env.PUBLIC_URL + `/images/new-form-template-image.png`
            }`}
          />
        </Grid>
      </Grid>
    </div>
  );
}
