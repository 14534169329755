import { Grid, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import styles from "../FormEntriesDashboard.module.css";
import React from 'react'
import { GridRowId, GridToolbarContainer } from '@mui/x-data-grid';
import { GridToolbarExport } from '@mui/x-data-grid';
import FormConstructor from "../../../../../components/Shared/FormConstructor";
import SimpleDialog from "../../../../../components/Dialogs/SimpleDialog";
import formsApi from '../../../formsApi';
import { useParams } from 'react-router-dom';
import { Field } from '../../../../../components/Shared/FormConstructor/FormConstructor';
export interface Props {
  rowsSelected: GridRowId[];
  loading: boolean;
  numSelected: number;
  setLoading: any;
  onDelete: any;
  formId: string;
  formFields: Array<Field>;
  addEntry: any;
}

export default function EntriesToolbar({ rowsSelected, loading, numSelected, setLoading, onDelete, formId, formFields, addEntry }: Props) {
  const [newEntry, setNewEntry] = React.useState(false);
  const setNewEntryFalse = () => setNewEntry(false);
  const [error, setError] = React.useState("");
  const [newEntrySubmitted, setNewEntrySubmitted] = React.useState(false);

  const onNewEntrySubmit = async (data: any) => {
    setLoading(true);
    formsApi
      .submitEntry(data, formId)
      .then((response) => {
        if (response.status == 201) {
          setNewEntrySubmitted(true);
          addEntry(response.data.entry)
        }
        setLoading(false);
        setNewEntryFalse();
      })
      .catch((error) => {
        setError(error.error.message);
      });
  };

  const NewEntryModal = newEntry && formFields && (
    <SimpleDialog
      description={"Manually add an entry"}
      onClose={setNewEntryFalse}
      title={"New Entry"}
      error={error ? true : false}
      errorMessage={error}
    >
      <FormConstructor onSubmit={onNewEntrySubmit} fields={formFields} />
    </SimpleDialog>
  );

  return (
    <GridToolbarContainer style={{ padding: 10, border:"1px solid rgba(224, 224, 224, 1)" }} >
      {NewEntryModal}
      <Grid container direction="row" justifyContent="flex-end" alignItems={"center"} >
        {numSelected > 0 ? (
          <Grid item md={6}>
            <Typography className={styles.Title} color="inherit" variant="subtitle1" component="div">
              {numSelected} selected
            </Typography>
          </Grid>
        ) : null}
        {numSelected > 0 ?
          (
            <Grid item md={2}>
              <Tooltip title="Download">
                <IconButton aria-label="download">
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => onDelete()} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
        <Grid item>
          <GridToolbarExport />
        </Grid>
        <Grid item>
          <Tooltip title="New Entry">
            <input
              onClick={() => setNewEntry(true)}
              className={styles.DashboardButton}
              value="New Entry"
              type="submit"
            // disabled={!!loading || !!error}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  )
}
