import { Button, IconButton, Tooltip } from '@mui/material';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import accountApi from '../../../features/account/accountApi';
import { accountInfo, setAccountInfo } from '../../../features/account/accountSlice';

export default function LoggedInMenu({ setOpenNewFormDialog }) {
  const dispatch = useDispatch();
  const info = useSelector(accountInfo);
  const history = useHistory();

  // if (Object.keys(info).length === 0) {
  //   accountApi.getAccount().then((response) => {
  //     if (response.status === 200) {
  //       dispatch(setAccountInfo(response.data.account))
  //     }
  //   })
  // }
  console.log(info);
  return (
    <div>
      <Button
        color="inherit"
        onClick={() => setOpenNewFormDialog(true)}
      >
        New Form
              </Button>
      <Tooltip title={info.email}>
        <IconButton onClick={() => history.push("/account")} size="large">
          <AccountCircleIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
