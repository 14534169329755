import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { createTheme } from "@mui/material/styles";
import { AccountCircle, CookieSharp, LockOpenRounded } from "@mui/icons-material";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import login from "./loginApi";
import { loginUser } from "./loginSlice";
import Cookies from "js-cookie";
import { setAccountInfo } from "../account/accountSlice";
import { gql, useMutation } from '@apollo/client';

// Define mutation
const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password){
      accessToken
      errors
    }
  }
`;

const theme = createTheme();
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex + 1,
    color: "#fff",
  },
}));

export default function Login() {
  const [signIn, { data, loading }] = useMutation(LOGIN);

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const errorSpan = error && (
    <Grid item>
      <span className="error-message">{errorMessage}</span>
    </Grid>
  );

  const loader = loading && (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress />
    </Backdrop>
  );

  return (
    <Container maxWidth="lg">
      {loader}
      <Paper elevation={3} className="login-paper">
        <h2>Sign In</h2>
        <form
          onSubmit={e => {
            e.preventDefault();
            signIn(
              { variables: { "email": username, "password": password },
              onCompleted: (data) => {
                if(data.login.errors.length !== 0){
                  setError(true)
                  setErrorMessage(data.login.errors[0])
                }else{
                  history.push('/')
                  Cookies.set("JAMFREE", data.login.accessToken)
                }
              } },
              );
          }}
        >
          <Grid container direction="column">
            {errorSpan}
            <Grid item>
              <InputLabel
                style={{ textAlign: "left" }}
                htmlFor="login-input-username"
              >
                Username
              </InputLabel>
              <Input
                value={username}
                required={true}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
                id="login-input-username"
                fullWidth
                label="Username"
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item style={{ marginTop: "20px" }}>
              <InputLabel
                style={{ textAlign: "left" }}
                htmlFor="login-input-password"
              >
                Password
              </InputLabel>
              <Input
                required={true}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                fullWidth
                id="login-input-password"
                startAdornment={
                  <InputAdornment position="start">
                    <LockOpenRounded />
                  </InputAdornment>
                }
                label="Password"
                type="password"
              />
            </Grid>
            <Grid item style={{ marginTop: "30px" }}>
              <Button type={"submit"} disableElevation variant="contained">
                Sign in
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid
          container
          style={{ marginTop: "30px" }}
          direction="row"
          justifyContent="center"
        >
          <Grid item xs={12} md={6}>
            <Link>Create new account</Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link>Forgot Password</Link>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
