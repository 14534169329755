// import { Badge, Container, Grid, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleDialog from "../../../../components/Dialogs/SimpleDialog";
import formsApi from "../../formsApi";
import Entries from "./components/Entries";
import styles from "./FormEntriesDashboard.module.css";
import FormConstructor from "../../../../components/Shared/FormConstructor";

import {
  Option,
  Field,
} from "../../../../components/Shared/FormConstructor/FormConstructor";
import Toast from "../../../../components/Shared/Toast";
import { Severity } from "../../../../components/Shared/Toast/Toast";
import { GridRowProps, GridValueGetterParams } from "@mui/x-data-grid";
import { Badge, Container, Link, Paper } from "@mui/material";
import { buildGridColumns } from "./helpers";
interface Params {
  id: string;
}
export interface Entry {
  submission: object;
  status: string;
  created_at: string,
  id: number;
}
export interface Form {
  title: string;
  created_at: string;
  description?: string;
  fields: Array<Field>;
}
const renderCellAs = (type: string) => {};

export default function FormEntriesDashboard() {
  const { id } = useParams<Params>();
  const columnsToHide = ["id"];

  const mockEntries = require("./mockEntries.json");
  const [formId, setFormId] = React.useState("");
  const [form, setForm] = React.useState<Form | undefined>(undefined);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const [entries, setEntries] = React.useState<Entry[] | undefined>(undefined);
  const [newEntry, setNewEntry] = React.useState(false);
  const [newEntrySubmitted, setNewEntrySubmitted] = React.useState(false);

  const setNewEntryFalse = () => setNewEntry(false);
  const onNewEntrySubmit = async (data: any) => {
    setLoading(true);
    formsApi
      .submitEntry(data, id)
      .then((response) => {
        if (response.status == 200) {
          setNewEntrySubmitted(true);
        }
        setLoading(false);
        setNewEntryFalse();
      })
      .catch((error) => {
        setError(error.error.message);
      });
  };
  const isSubmissionField = (name: string) => {
    let index = form.fields.findIndex((field) => field.name === name);
    if (index >= 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setLoading(true);
    formsApi
      .getForm(id)
      .then((response) => {
        if (response.status == 200) {
          setForm(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);
  //test form_id: ktf4g81k
  const NewEntryModal = newEntry && (
    <SimpleDialog
      description={"Manually add an entry"}
      onClose={setNewEntryFalse}
      title={"New Entry"}
      error={error ? true : false}
      errorMessage={error}
    >
      <FormConstructor onSubmit={onNewEntrySubmit} fields={form?.fields} />
    </SimpleDialog>
  );

  const SuccessEntrySubmissionToast = newEntrySubmitted && (
    <Toast
      onClose={setNewEntrySubmitted}
      severity={Severity.SUCCESS}
      message={"Entry submitted"}
    />
  );

  return (
    <Container maxWidth="lg" style={{paddingBottom:"40px", height:"110vh"}}>
      {NewEntryModal}
      {SuccessEntrySubmissionToast}
      <div className={styles.FormTitle}>
        <h2>{form?.title}</h2>
        <div>created on: {form?.created_at}</div>
      </div>
      {/* <div className={styles.FormActionsContainer}>
        <input
          onClick={() => setNewEntry(true)}
          className={styles.DashboardButton}
          value="New Entry"
          type="submit"
          disabled={!!loading || !!error}
        />
      </div> */}
      <Paper style={{height:"90%"}} elevation={2}>
        <Entries
          formId={id}
          isSubmissionField={isSubmissionField}
          columns={form ? buildGridColumns(form.fields) : []}
          formFields={form?.fields}
        />
      </Paper>
    </Container>
  );
}
