import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn, loginUser } from "../../features/login/loginSlice";
import MyEvents from "./components/MyEvents";
import MyForms from "./components/MyForms";
import Cookie from "js-cookie";
import accountApi from '../../features/account/accountApi';
import { setAccountInfo } from "../../features/account/accountSlice";

export default function Home() {
  const loggedIn = useSelector(isLoggedIn);
  const dispatch = useDispatch();

  const fetchAccount = async () => {
    return accountApi.getAccount().then((response) => {
      return response.data;
    })
  }
  useEffect(() => {
    if (Cookie.get("JAMFREE")) {
        dispatch(loginUser())
    }
    fetchAccount().then((account) => {
      dispatch(setAccountInfo(account))
    })
  }, []);
  return (
    <Grid container direction="column">
      <Grid item>
        <MyForms />
      </Grid>
      <Grid item>
        <MyEvents />
      </Grid>
    </Grid>
  );
}
