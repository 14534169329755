import Cookies from "js-cookie";
const { default: requests } = require("../../helpers/http");
const formsEndpoint = "https://enigmatic-beach-93405.herokuapp.com/forms";
const entriesEndpoint = "https://enigmatic-beach-93405.herokuapp.com/entries";


const formsApi = {
  getForms: () =>{
    return requests.get(`${formsEndpoint}`);
  },
  getForm:(form_id) => {
    let account_id = Cookies.get("ACCOUNT_ID");
    return requests.get(`${formsEndpoint}/${form_id}`)
  },
  getFormEntries:(formId) => {
    return requests.get(`${formsEndpoint}/${formId}/entries`)
  },
  submitEntry:(data, form_id) => {
    return requests.post(`${formsEndpoint}/${form_id}/entries`, {entry: { submission: data}})
  },
  updateEntry:(data, form_id, entry_id) => {
    console.log(data)
    return requests.put(`${formsEndpoint}/${form_id}/${entry_id}`, data)
  }
}

export default formsApi;
