import { Grid, MenuItem } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import ReactHookTextField from './components/ReactHookTextfield';
import ReactHookSelect from './components/ReactHookSelect';
import styles from '../style.module.css';

export interface Option {
  value: string,
  key: string,
}
export interface Field {
  kind: string;
  name: string;
  label: string;
  required: boolean;
  half_size?: boolean;
  options?: Array<Option>;
  children?: React.ReactNode;
  fullWidth: boolean;
  control: any;
}
export interface Props {
  fields: Field[];
  onSubmit: any;
}

export default function FormConstructor({ fields, onSubmit }: Props) {
  const {
    control,
    handleSubmit,
  } = useForm()
  const uniqueFormId = (index: number) => {
    return `form-field-${index}`
  }
  const uniqueDropDownId = (index: number) => {
    return `form-option-${index}`
  }
  console.log(fields);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" className={styles.FormConstructorContainer} >
        {fields?.map((item, index) => {
          switch (item.kind) {
            case "select":{
              return (
                <Grid item xs={12} md={12} key={uniqueFormId(index)}>
                  <ReactHookSelect
                    required={item.required} 
                    label={item.label}
                    kind={"select"}
                    name={item.name}
                    fullWidth={true}
                    control={control}
                  >
                    {item.options?.map((option, optionIndex) => (
                      <MenuItem value={option.value} key={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </ReactHookSelect>
                </Grid>
              )
            }
            case "email":
            case "tel":
            case "text": {
              return (
                <Grid xs={12} md={12} item key={uniqueFormId(index)}>
                  <ReactHookTextField
                    label={item.label}
                    required={item.required}
                    name={item.name}
                    kind={item.kind}
                    control={control}
                    fullWidth={true}
                  />
                </Grid>
              )
            }
          }
        })}
        <Grid item>
          <input className={styles.FormConstructorSubmit} type="submit" value="Submit" />
        </Grid>
      </Grid>
    </form>
  )
}
