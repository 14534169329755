const { default: requests } = require("../../helpers/http");
const accountEndpoint = "https://enigmatic-beach-93405.herokuapp.com/accounts";

const accountApi = {
  getAccount: () =>{
    return requests.get(`${accountEndpoint}`);
  }
}

export default accountApi;
