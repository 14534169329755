import { Box, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react'
import accountApi from '../../features/account/accountApi';
import AccountDetails from "./components/AccountDetails";
import { createTheme } from '@mui/material/styles';

function TabPanel(props: any) {
  const { children, menuTab, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={menuTab !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ backgroundColor: "#e1e1e1", width: "100%" }}
      {...other}
    >
      {menuTab === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
const theme = createTheme();

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: "100%"
  }
});

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

export default function Account({ }) {
  const classes = useStyles();

  const [account, setAccount] = React.useState({ 
    name: "", 
    accountType: "",
    username: "",
    createdOn: "",
    email:"" 
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [menuTab, setMenuTab] = React.useState(0);

  const handleChange = (event: any, newMenuTab: number) => {
    setMenuTab(newMenuTab);
  };

  useEffect(() => {
    setLoading(true);
    accountApi.getAccount().then((response) => {
      if (response.status === 200) {
        setAccount(response.data.account);
      }
    }).then(() => setLoading(false)).catch((error) => {
      setError(true);
      setLoading(false);
    });
  }, []);

  console.log(`menuTab: ${menuTab}`)
  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={menuTab}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Account Details" {...a11yProps(0)} />
        <Tab label="Item Two" {...a11yProps(1)} />
        <Tab label="Item Three" {...a11yProps(2)} />
        <Tab label="Item Four" {...a11yProps(3)} />
        <Tab label="Item Five" {...a11yProps(4)} />
        <Tab label="Item Six" {...a11yProps(5)} />
        <Tab label="Item Seven" {...a11yProps(6)} />
      </Tabs>
      <TabPanel menuTab={menuTab} index={0}>
        <AccountDetails 
          accountType={account.accountType} 
          username={account.email}
          createdOn={account.createdOn}
          name={account.name}
        />
      </TabPanel>
      <TabPanel menuTab={menuTab} index={1}>
        Item Two
      </TabPanel>
      <TabPanel menuTab={menuTab} index={2}>
        Item Three
      </TabPanel>
      <TabPanel menuTab={menuTab} index={3}>
        Item Four
      </TabPanel>
      <TabPanel menuTab={menuTab} index={4}>
        Item Five
      </TabPanel>
      <TabPanel menuTab={menuTab} index={5}>
        Item Six
      </TabPanel>
      <TabPanel menuTab={menuTab} index={6}>
        Item Seven
      </TabPanel>
    </div>
  )
}
