import { Grid, TextField } from "@mui/material";
import React from "react";
import Draggable from "react-draggable";
import FormTextField from "./components/FormTextField";

export default function FormFields({ fields }) {
  return (
    <Grid container direction="row" className="form-fields">
      {fields.map(({ type, name, label, half_size, required }, index) => {
        switch (type) {
          case "email":
          case "tel":
          case "text":
            return (
              <FormTextField
                type={type}
                name={name}
                label={label}
                position={index}
                half_size={half_size}
                required={required}
              />
            );
          default:
            break;
        }
      })}
    </Grid>
  );
}
