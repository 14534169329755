import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const columns = [
  { id: "formName", label: "Form Title", minWidth: 170 },
  { id: "formDesc", label: "Form Description", minWidth: 170 },
  { id: "formType", label: "Form Type", minWidth: 170 },
  { id: "formFields", label: "Number of Fields", minWidth: 170 },
  { id: "formEntries", label: "Submitted Entries", minWidth: 170 },
];

function createData(formName, formDesc, formType, formEntries, formFields) {
  return { formName, formDesc, formType, formEntries, formFields };
}

const rows = [
  createData("Summer Camp Registration", "Summer camp 2021 registration form", "Event registration with waitlist", 12, 6),
  createData("Feedback Survey", "Customer service feedback survey", "Survey", 23, 5),
  createData("Attendance Sheet", "Attendance tracking for events", "Survey", 53, 3),
  createData("Marketing Info", "To collect info for email marketing", "Survey", 15, 7),
  createData("Back-to-School Party Registration", "Party registration form", "Event registration with waitlist", 43, 9)
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom:"30px"
  },
  container: {
    maxHeight: 440
  }
});

function addRowHandlers() {
  var table = document.getElementById("tableId");
  var rows = table.getElementsByTagName("tr");
  for (var i = 0; i < rows.length; i++) {
    var currentRow = table.rows[i];
    var createClickHandler =
      function (row) {
        return function () {
          var cell = row.getElementsByTagName("td")[0];
          alert("id:" + cell);
        };
      };
    currentRow.onclick = createClickHandler(currentRow);
  }
}




export default function MyFormsTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>

                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}