import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import {Field} from "../FormConstructor";

const ReactHookSelect = ({
  name,
  label,
  control,
  required,
  children,
  fullWidth,
}: Field) => {
  const labelId = `${name}`;
  let rules = {required: ""}
  if (required){
    rules['required']= `${label} is required.`
  }
  return (
    <FormControl variant="standard" fullWidth={fullWidth}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        rules={rules}
        render={({ field, fieldState: { error }}) => {
          return (
            <Select 
              fullWidth={fullWidth} 
              required={required} 
              error={!!error} 
              {...field} 
              labelId={labelId} 
              label={label}
              variant="standard"
            >
              {children}
            </Select>
          );
        }}
        name={name}
        control={control}
      />
    </FormControl>
  );
};
export default ReactHookSelect;