import React from "react";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {
  Box,
  ButtonGroup,
  Drawer,
  IconButton,
  Grid,
  Checkbox,
  TextField,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DiscreteSlider from "../../../Shared/DiscreteSlider";
import { SketchPicker } from "react-color";

const useStyles = makeStyles({
  drawer: {
    width: "500px",
  },
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
});

const PropertyTitle = ({ title }) => {
  return (
    <Box width={"100%"} style={{ display: "inline-block" }}>
      <h3>{`${title}`}</h3>
    </Box>
  );
};

export default function FormHeaderProperties({
  close,
  setSize,
  setTextAlign,
  setTitle,
  setWeight,
  fontWeight,
  title,
  color,
  setColor,
  fontFamily,
  setFontFamily
}) {
  const classes = useStyles();
  const [state, setState] = React.useState(false);

  const titleSizeButtonGroup = (
    <ButtonGroup
      variant="contained"
      color="primary"
      aria-label="contained primary button group"
    >
      <Button onClick={() => setSize("small")}>Small</Button>
      <Button onClick={() => setSize("medium")}>Medium</Button>
      <Button onClick={() => setSize("large")}>Large</Button>
    </ButtonGroup>
  );

  const titleAlignButtonGroup = (
    <ButtonGroup
      variant="contained"
      color="primary"
      aria-label="contained primary button group"
    >
      <Button onClick={() => setTextAlign("left")}>Left</Button>
      <Button onClick={() => setTextAlign("center")}>Center</Button>
      <Button onClick={() => setTextAlign("right")}>Right</Button>
    </ButtonGroup>
  );

  const colorPicker = (
    <SketchPicker color={color} onChangeComplete={(e) => setColor(e.hex)} />
  );
  const toggleDrawer = (open) => (event) => {
    close(false);
    setState(false);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem>
          <div className={classes.drawerHeader}>
            <IconButton onClick={toggleDrawer(false)} size="large">
              <ChevronRightIcon />
            </IconButton>
          </div>
        </ListItem>
        <ListItem>
          {/* <Grid container direction="column">
            <Grid item>
              <PropertyTitle title={"Title"} />
            </Grid>
            <Grid item>
              <TextField
                onChange={(e) => setTitle(e.target.value)}
                variant="outlined"
                fullWidth
                defaultValue={title}
                value={title}
              />
            </Grid>
          </Grid> */}
        </ListItem>
        <ListItem>
          <Grid container direction="column">
            <Grid item>
              <PropertyTitle title={"Title size"} />
            </Grid>
            <Grid item>{titleSizeButtonGroup}</Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container direction="column">
            <Grid item>
              <PropertyTitle title={"Title align"} />
            </Grid>
            <Grid item>{titleAlignButtonGroup}</Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container direction="column">
            <Grid item>
              <PropertyTitle title={"Title bold"} />
            </Grid>
            <Grid item>
              <DiscreteSlider
                setValue={setWeight}
                step={100}
                defaultValue={fontWeight}
                min={100}
                max={1000}
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container direction="column">
            <Grid item>
              <PropertyTitle title={"Color"} />
            </Grid>
            <Grid item>{colorPicker}</Grid>
          </Grid>
        </ListItem>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem component="li" button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          variant="persistent"
          anchor={"right"}
          open={true}
          classes={{ root: classes.drawer }}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
