import React from 'react'
import MyFormsTable from "./components/MyFormsTable";
import MyFormsEntries from './components/MyFormsEntries';
import { Container } from '@mui/material';
import CardForm from "../../../../features/forms/components/CardForm";
import Forms
 from '../../../../features/forms/Forms';
export default function MyForms() {
  return (
    <Forms/>
  )
}
