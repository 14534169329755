import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import FormBuilder from "../components/FormBuilder";
import Home from "../components/Home";
import Navigation from "../components/Navigation";
import Login from "../features/login/Login";
import Account from "../components/Account";
import FormEntriesDashboard from "../features/forms/components/FormEntriesDashboard";
import Forms from "../features/forms";

export default function Routes() {
  return (
    <Router>
      <Navigation />
      <Switch>
        <Route exact path="/account">
          <Account/>
        </Route>
        <Route exact path="/forms">
          <Forms/>
        </Route>
        <Route exact path="/forms/:id">
          <FormEntriesDashboard/>
        </Route>
          <Route path="/build">
              <FormBuilder/>
          </Route>
          <Route exact path="/">
              <Home/>
          </Route>
          <Route exact path="/login">
              <Login/>
          </Route>
      </Switch>
    </Router>
  );
}
