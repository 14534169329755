import React, { Children } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export enum Severity {
  SUCCESS = "success",
  WARNING = "warning",
}
interface AlertProps {
  children: React.ReactNode;
  severity: Severity;
}
interface ToastProps {
  message: string;
  severity: Severity;
  onClose: any;
}
function Alert({ severity, children }: AlertProps) {
  return (
    <MuiAlert elevation={6} variant="filled" severity={severity}>
      {children}
    </MuiAlert>
  );
}

export default function Toast({ severity, message, onClose }: ToastProps) {
  const handleClose = () => {
    onClose(false);
  };
  return (
    <div>
      <Snackbar onClose={handleClose} open={true} autoHideDuration={6000}>
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </div>
  );
}
