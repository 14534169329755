import { Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react'
import styles from "./CardForm.module.css";

export interface Props {
  title: string;
  type?: string;
  src?: string;
  id?: number;
}

export default function CardForm({ title, type, src, id }: Props) {
  const defaultImageSrc = "https://jamfree-media.s3.amazonaws.com/website-images/new-form-template-image.png";

  return (
    <Card className={styles.CardForm} key={`form-${id}`}>
      <CardMedia component="img" height="140" className={styles.CardMedia} image={defaultImageSrc} />
      <CardContent>
        <Typography className={"MuiTypography--heading"} variant={"h6"} gutterBottom>
          {title}
        </Typography>
      </CardContent>
      <CardActionArea>
        <Button size="small" >Submissions</Button>
        <Button size="small" >Form</Button>
      </CardActionArea>
    </Card>
  )
}
