import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Input, InputLabel, MenuItem, OutlinedInput, Paper, Select, TextField } from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import Dropdown from '../../../../Shared/Dropdown';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function FormSettingsBase() {
  const text = require("../en.json").form_settings_base;
  const formStatusDropdown = text.form_status;

  const classes = useStyles();

  const [formDescription, setFormDescription] = React.useState('');
  const [enableWailist, setEnableWaitlist] = React.useState(false);
  const [formStatus, setFormStatus] = React.useState("enabled");

  const handleChange = (value) => {
    setFormDescription(value)
  }
  const handleFormStatusChange = (value) => {
    setFormStatus(value);
  };
  const handleWaitlistChange = (value) => {
    setEnableWaitlist(value)
  };
  console.log(formStatus)
  const formStatusMarkup = (
    <Grid item>
      <h4>Form Status</h4>
        <Dropdown
          value={formStatus}
          key={formStatusDropdown.key}
          onChange={setFormStatus}
          label={formStatusDropdown.label}
          options={formStatusDropdown.options}
          name={formStatusDropdown.name}
        />
      {formStatus === "disable_on_date" ?
        <h4>Disable on</h4> : null}
    </Grid>
  )
  const waitListSettings = enableWailist && (
    <Grid item>
      <TextField
        margin="normal"
        id="standard-number"
        label="Limit number of waitlist"
        fullWidth
        defaultValue={100}
        variant="outlined"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  )
  const formEntriesMarkup = (
    <Grid item>
      <h4>Form Entries</h4>
      <p>Manage how your form submission should behave.</p>
      <TextField
        margin="normal"
        id="standard-number"
        label="Limit number of entries"
        fullWidth
        defaultValue={100}
        variant="outlined"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControlLabel
        control={<Checkbox checked={enableWailist} onChange={(e) => handleWaitlistChange(e.target.checked)} name="antoine" />}
        label="Enable waitlist"
      />
      <FormHelperText>If checked once form limit is reached, form submission would be waitlisted.</FormHelperText>
    </Grid>
  )
  const formDescriptionMarkup = (
    <TextField placeholder="description" variant="outlined" helperText="Add a description to help you and ur users." />
  )
  return (
    <Paper className="form-settings-paper">
      <form>
        <Grid container direction="column">
          {formStatusMarkup}
          <Grid item>
            {formDescriptionMarkup}
          </Grid>
          {formEntriesMarkup}
          {waitListSettings}
        </Grid>
      </form>
    </Paper>
  )
}
