import { Grid } from "@mui/material";
import React from "react";
import SettingsButtons from "../Buttons/SettingsButtons";

export default function FormSubmission({footerProperties, setFooterProperties}) {

  const [openProperties, setOpenProperties] = React.useState(false);
  
  return (
    <Grid item className="form-submission">
      <div>
        <input  className="btn-primary" value="Submit" type="submit" />
      </div>
      <SettingsButtons value={openProperties} onClick={setOpenProperties}/>
    </Grid>
  );
}
