import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormSettingsBase from './components/FormSettingsBase';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="form-settings"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ paddingLeft: "100px", width:"100%" }}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: "100%",
    width: "100%",
    position: "absolute"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width:"300px"
  },
}));

export default function FormSettings() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Form Settings" {...a11yProps(0)} />
        <Tab label="Post Submission" {...a11yProps(1)} />
        <Tab label="Notifications" {...a11yProps(2)} />
        <Tab label="Integrations" {...a11yProps(3)} />

      </Tabs>
      <TabPanel value={value} index={0}>
        <FormSettingsBase />
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Post submission
      </TabPanel>
      <TabPanel value={value} index={3}>
        Integrations
      </TabPanel>
    </div>
  );
}
