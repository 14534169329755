import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'
import React from 'react'

export interface Props {
  title: string,
  onClose: any,
  children: React.ReactNode,
  description?: string,
  onConfirm: any,
}
export default function DeleteModal({ title, children, onClose, description, onConfirm }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {description ? <DialogContentText>{description}</DialogContentText> : null}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
          </Button>
        <Button onClick={() => onConfirm()} color="primary" autoFocus>
          Delete
          </Button>
      </DialogActions>
    </Dialog>
  )
}
