import { Badge, Link, TextField } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/system';
import { GridValueGetterParams } from "@mui/x-data-grid";
import moment from "moment";
import { Field } from "../../../../components/Shared/FormConstructor/FormConstructor";

function renderBadge(value: string, colorFinder: any) {
  const ColorHash = require('color-hash').default;

  var colorHash = new ColorHash();
  let customBadge = {
    backgroundColor: `${colorHash.hex(value)}`,
    color: "white"
  }
  return (
    <div style={{ margin: "auto" }}>
      <Badge 
        badgeContent={value}
        color={colorFinder(value)}
         />
    </div>
  );
}

const columnHeaderName = (field: string) => {
  let fields = field.split("_");
  let headerName = "";
  fields.forEach((field, index) => {
    headerName += field.charAt(0).toUpperCase() + field.slice(1);
    if (index !== fields.length - 1) {
      headerName += " ";
    }
  });
  return headerName;
};

const determineStatusBadgeColor = (value: string) => {
  switch (value) {
    case "registered":
      return "success"
    case "waitlisted":
      return "warning"
  }
}

function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function buildGridColumns(fields: Field[]) {
  const columnsToHide = ["id"];
  let columns = fields.map(({ name, kind }, index) => {
    switch (kind) {
      case "select":
        return {
          field: name,
          flex: 1,
          editable: true,
          headerName: columnHeaderName(name),
          renderCell: (params: GridValueGetterParams) => {
            return (
              <div style={{ margin: "auto" }}>
                <Badge badgeContent={params.value} color="secondary" />
              </div>
            );
          },
          hide: columnsToHide.includes(name),
        };
      case "tel":
        return {
          field: name,
          flex: 1,
          editable: true,
          headerName: columnHeaderName(name),
          hide: columnsToHide.includes(name),
          renderCell: (params: GridValueGetterParams) => {
            return formatPhoneNumber(params.value);
          }
        };
      case "date":
        return {
          field: name,
          flex: 0,
          type: "date",
          editable: true,
          headerName: columnHeaderName(name),
          hide: columnsToHide.includes(name),
          // renderCell: (params: GridValueGetterParams) => {
          //   return (
          //     <TextField
          //       variant="standard"
          //       InputProps={{ disableUnderline: true }}
          //       type="date"
          //       style={{ border: "none" }}
          //       defaultValue={moment(Date.parse(params.value)).format(
          //         "YYYY-MM-DD"
          //       )}
          //       InputLabelProps={{ shrink: true }}
          //     />
          //   );
          // }
        };
      case "text":
        return {
          field: name,
          flex: 1,
          editable: true,
          headerName: columnHeaderName(name),
          hide: columnsToHide.includes(name),
        };
      case "email":
        return {
          field: name,
          flex: 1,
          type: "string",
          editable: true,
          headerName: columnHeaderName(name),
          hide: columnsToHide.includes(name),
          renderCell: (params: GridValueGetterParams) => {
            return (
              <Link href="#" color="inherit" underline="always">
                {params.value}
              </Link>
            );
          },
        };
      default:
        return;
    }
  });

  columns.push({
    field: "created_at",
    flex: 1,
    type: "datetime",
    editable: false,
    headerName: columnHeaderName("created_at"),
    hide: false,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <div>
          {moment(Date.parse(params.value)).format(
            "YYYY-MM-DD HH:MM:SS"
          )}
        </div>
      )
    }
  },
    {
      field: "status",
      flex: 1,
      editable: false,
      headerName: columnHeaderName("status"),
      hide: false,
      renderCell: (params: GridValueGetterParams) => renderBadge(params.value, determineStatusBadgeColor)
    })
  return columns;
}
