// import { Container } from '@material-ui/core'
import { Container, Grid } from '@mui/material';
import React from 'react'
import CardForm from './components/CardForm';
import formsApi from './formsApi';

export interface Form {
  title: string;
  created_at: string,
  id: number;
  disabled: boolean,
  url: string;
}

export default function Forms() {
  const [forms, setForms] = React.useState<Form[] | []>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    formsApi.getForms().then((response) => {
      setForms(response.data);
    })
  }, []);
  console.log(forms)
  return (
    <Container maxWidth="lg">
      <h2> My Forms</h2>
      <Grid justifyItems={"center"} alignContent={"center"} container direction={"row"}>
        {forms.map((form, index) => {
          return (
            <Grid padding={5} lg={4} item key={index}>
              <CardForm
                title={form.title}
                id={form.id}
              />
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}
