import { AppBar, Container, Dialog, Icon, IconButton, Toolbar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    marginBottom: "100px",
    color: "black",
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialogs = ({ closeDialog, children, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    closeDialog(false);
  };
  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "#F0F0F0",
          boxShadow: "none",
        },
      }}
      TransitionComponent={Transition}
      fullScreen
      open={true}
      onClose={handleClose}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            style={{ color: "black" }}
            onClick={handleClose}
            edge="start"
            color="inherit"
            size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">{children}</Container>
    </Dialog>
  );
};
export default FullScreenDialogs;
