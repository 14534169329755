import { AppBar, Dialog, Grid, IconButton, Toolbar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import Slide from "@mui/material/Slide";
import FromScratch from "./components/FromScratch";
import FullScreenDialogs from "../Dialogs/FullScreenDialogs";
import FromTemplate from "./components/FromTemplate";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    position: "relative",
    marginBottom: "100px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewFormDialog = ({ closeDialog, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const close = () => {
      closeDialog(false)
  }
  return (
    <FullScreenDialogs closeDialog={close}>
      <Grid container direction="row">
        <Grid item xs={4}>
            <FromScratch closeDialog={close}/>
        </Grid>
        <Grid item item xs={4}>
            <FromTemplate/>
        </Grid>
      </Grid>
    </FullScreenDialogs>
  );
};
export default NewFormDialog;
