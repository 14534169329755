import { Grid, InputBase } from "@mui/material";
import React from "react";
import SettingsButtons from "../Buttons/SettingsButtons";
import FormHeaderProperties from "./FormHeaderProperties";

export default function FormHeader({ size = "small", align = "center", headerProperties, setHeaderProperties }) {
  const [openHeaderProperties, setOpenHeaderProperties] = React.useState(false);
  // const [headerProperties, setHeaderProperties] = React.useState({
  //   size: "medium",
  //   label: "",
  //   textAlign: "left",
  //   fontWeight: 400,
  //   color: "#000000",
  //   fontFamily:"inherit"
  // });


  const setSize = (size) => {
    setHeaderProperties({ ...headerProperties, size: size });
  };
  const setTextAlign = (align) => {
    setHeaderProperties({ ...headerProperties, textAlign: align });
  };
  const setTitle = (title) => {
    setHeaderProperties({ ...headerProperties, label: title });
  };
  const setWeight = (weight) => {
    setHeaderProperties({ ...headerProperties, fontWeight: weight });
  };
  const setColor = (color) => {
    setHeaderProperties({ ...headerProperties, color: color });
  };
  const setFontFamily = (family) => {
    setHeaderProperties({ ...headerProperties, fontFamily: family });
  }

  const fontSize = (size) => {
    switch (size) {
      case "large":
        return 44;
      case "medium":
        return 34;
      case "small":
        return 30;
    }
  };
  const propertiesDialog = openHeaderProperties && (
    <FormHeaderProperties
      close={setOpenHeaderProperties}
      title={headerProperties.label}
      setSize={setSize}
      setTextAlign={setTextAlign}
      setTitle={setTitle}
      setWeight={setWeight}
      fontWeight={headerProperties.fontWeight}
      color={headerProperties.color}
      setColor={setColor}
    />
  );
  console.log(headerProperties);
  return (
    <Grid key={0} item className="form-header">
      {propertiesDialog}
      <InputBase
        onChange={(e) => setTitle(e.target.value)}
        style={{
          fontSize: `${fontSize(headerProperties.size)}px`,
        }}
        fullWidth
        value={headerProperties.label}
        defaultValue="Form Title"
        placeholder={"Form title"}
        inputProps={{
          "aria-label": "naked",
          style: {
            textAlign: `${headerProperties.textAlign}`,
            fontWeight: `${headerProperties.fontWeight}`,
            color: `${headerProperties.color}`,
          },
        }}
      />
      <SettingsButtons onClick={setOpenHeaderProperties} value={openHeaderProperties} />
    </Grid>
  );
}
