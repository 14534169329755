import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import TextFieldSettingsDialog from "../../FieldsSettingsDialogs/TextFieldSettingsDialog";
import EmailSettingDialog from "./EmailSettingDialog";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  fieldComponentsTitle: {
    padding: "10px",
    fontWeight: "bolder",
    fontSize: "20px",
  },
});
const jsonFields = [
  { type: "text" },
  { type: "email", name: "email", label: "Email" },
];
export default function FieldsDrawer({ closeDrawer, addField }) {
  const classes = useStyles();
  const [fieldType, setFieldType] = React.useState("");
  const [openFieldSettingDialog, setOpenFieldSettingDialog] =
    React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    closeDrawer(false);
    setState(false);
  };
  const closeFieldTypeDialog = (value) => {
    setFieldType(value);
    setState(value);
  };
  const textFieldSettings = fieldType && fieldType === "text" && (
    <TextFieldSettingsDialog addField={addField} close={closeFieldTypeDialog} />
  );

  const emailFieldSetting = fieldType && fieldType === "email" && (
    <EmailSettingDialog addField={addField} close={closeFieldTypeDialog} />
  )
  const list = () => (
    <div
      role="presentation"
      onKeyDown={toggleDrawer(false)}
      onKeyDownCapture={toggleDrawer(false)}
      style={{ width: "400px" }}
    >
      <div className={classes.fieldComponentsTitle}>Field Components</div>
      <List>
        {[
          { label: "Text Field", value: "text" },
          { label: "Email", value: "email" },
          { label: "Drop down", value: "select" },
          { label: "Checkbox", value: "checkbox" },
        ].map(({ label, value }, index) => (
          <ListItem onClick={() => setFieldType(value)} button key={value}>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );
  console.log(fieldType);
  return (
    <div>
      {textFieldSettings}
      {emailFieldSetting}
      <React.Fragment>
        <Drawer open={true} onClose={toggleDrawer(false)} anchor={"left"}>
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
