import {
  Alert,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
} from "@mui/material";
import React from "react";
import Toast from "../../Shared/Toast";
import { Severity } from "../../Shared/Toast/Toast";

export interface Props {
  title: string;
  onClose: any;
  children: React.ReactNode;
  description?: string;
  error?: boolean;
  errorMessage?: string;
}
export default function SimpleDialog({
  title,
  children,
  onClose,
  description,
  error,
  errorMessage,
}: Props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    onClose();
  };
  console.log(error);
  return (
    <Dialog onClose={handleClose} open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {error ? (
          <Alert severity="warning">
            {errorMessage}
          </Alert>
        ) : null}
        {description ? (
          <DialogContentText>{description}</DialogContentText>
        ) : null}
        {children}
      </DialogContent>
    </Dialog>
  );
}
