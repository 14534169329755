import { Grid, TextField } from "@mui/material";
import React from "react";

export default function FormTextField({ type, half_size, label, name, position, required }) {
  return (
    <Grid key={`form-item-${position}`} item xs={12} md={half_size ? 6 : 12}>
      <TextField
        type={type}
        key={`form-field-${position}`}
        fullWidth
        variant="outlined"
        name={name}
        label={label}
        type={type}
        margin="normal"
        required={required}
      />
    </Grid>
  );
}
