import { Button, Grid, Paper, TextField } from '@mui/material'
import React from 'react'
import styles from "./AccountDetails.module.css";
import EditIcon from '@mui/icons-material/Edit';

export interface Props {
  accountType: string;
  username: string;
  createdOn: string;
  name: string;
  phoneNumber?: string;
}
export default function AccountDetails({ accountType, username, createdOn, name, phoneNumber }: Props) {
  const [editDetails, setEditDetails] = React.useState(false);
  const [accountDetails, setAccountDetails] = React.useState({
    name: name,
    address: ""
  })
  console.log(username, editDetails)
  return (
    <div style={{ width: "100%" }}>
      <Paper className={styles.AccountDetails} style={{ width: "100%" }} elevation={3}>
        <Grid container direction="column">
          <Grid item>
            {editDetails ? <TextField value={name} /> : <div> Name: {name}</div>}
          </Grid>
          <Grid item>
            <div>
              Account Type: {accountType}
            </div>
          </Grid>
          <Grid item>
            username: {username}
          </Grid>
        </Grid>
        <Grid item className={styles.AccountDetailsActions}>
          { }
          <Button
            startIcon={editDetails ? null : <EditIcon />}
            onClick={() => setEditDetails(!editDetails)}
          >
            {editDetails ? "Cancel" : "Edit"}
          </Button>
          {editDetails ?
            <Button>
              Save
            </Button> 
            :null
          }
        </Grid>
      </Paper>
    </div>
  )
}
