import {
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Toolbar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import React from "react";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

const theme = createTheme();
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    marginBottom: "100px",
    color: "black",
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FieldSettingsDialog = ({
  closeDialog,
  title,
  id,
  save,
  children,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    closeDialog(false);
  };
  const handleSave = () => {
    if (save()) {
      handleClose();
    }
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "#F0F0F0",
              boxShadow: "none",
              width: "400px",
            },
          }}
          TransitionComponent={Transition}
          open={true}
          onClose={handleClose}
          aria-labelledby={`field-settings-dialog-${id}`}
        >
          <DialogTitle id={`field-settings-dialog-${id}`}>{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default FieldSettingsDialog;
