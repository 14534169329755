import axios from "axios";
import Cookies from "js-cookie";

axios.interceptors.request.use(function (config) {
  if (Cookies.get("JAMFREE")) {
    config.headers.Authorization = `Bearer ${Cookies.get("JAMFREE")}`;
    config.headers["X-Source"] = "admin";
    config.headers["Content-Type"] = "application/json";
    return config;
  } else {
    return config;
  }
});
axios.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  (error) => {
    console.log(error);
    if (error.response) {
      if (
        window.location.pathname !== "/login" &&
        error.response.status === 401
      ) {
        window.location = "/login";
      }
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

const requests = {
  get: (url) => {
    return axios.get(url);
  },
  post: (url, data) => {
    return axios.post(url, data);
  },
  put: (url, data) => {
    return axios.put(url, data);
  },
  delete: (url, data) => {
    return axios.delete(url, data);
  },
  postMedia: (url, data) => {
    return axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
export default requests;
