import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: "100%",
  },
});

export default function FormNavigation({ setMenuSelect }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const menu = ["builder", "settings", "preview"];
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setMenuSelect(menu[newValue]);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Build" />
        <Tab label="Settings" />
        <Tab label="Preview" />
      </Tabs>
    </Paper>
  );
}
