import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";

export default function SettingsButtons({ onClick, value }) {
  return (
    <div className="form-field-controls" onClick={() => onClick(!value)} component="span">
      <IconButton size="large">
        <SettingsIcon />
      </IconButton>
    </div>
  );
}
