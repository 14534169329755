import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../../features/login/loginSlice";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NewFormDialog from "../NewFormDialog/NewFormDialog";
import { useHistory } from "react-router-dom";
import LoggedInMenu from "./components/LoggedInMenu";
import { AppBar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// const theme = createTheme();
// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     color: "black",
//   },
//   appBar: {
//     color: "white",
//     backgroundColor: "#008060",
//   },
//   menuButton: {
//     // marginRight: theme.spacing(2),
//   },
//   grow: {
//     flexGrow: 1,
//   },
// }));

const useSyles = makeStyles({
  appBar:{
    backgroundColor:"#008060",
    color:"#e1e1e1"
  },
  grow: {
    flexGrow: 1,
  },
})
export default function Navigation() {
  const [openNewFormDialog, setOpenNewFormDialog] = React.useState(false);
  const history = useHistory();
  const styles = useSyles()
  const loggedIn = useSelector(isLoggedIn);
  const newFormDialog = openNewFormDialog && (
    <NewFormDialog closeDialog={setOpenNewFormDialog} />
  );

  return (
    <div>
      {newFormDialog}
      <AppBar style={{backgroundColor:"#008060", color:"white"}} position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <div className={styles.grow} />
          {loggedIn ? (
            <LoggedInMenu setOpenNewFormDialog={setOpenNewFormDialog} />
          ) : (
            <div>
              <Button color="inherit" onClick={() => history.push("/login")}>
                Login
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
