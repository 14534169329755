import { Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn, loginUser } from "../../features/login/loginSlice";
import AddFieldButton from "./components/AddFieldButton";
import FieldsDrawer from "./components/FormFields/components/FieldsDrawer";
import FormFields from "./components/FormFields/FormFields";
import FormHeader from "./components/FormHeader";
import FormNavigation from "./components/FormNavigation";
import FormSubmission from "./components/FormSubmission/";
import FormSettings from "./components/FromSettings";
import utilities from "./utilities";

export default function FormBuilder() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(isLoggedIn);
  const [openFieldDrawer, setOpenFieldDrawer] = React.useState(false);
  const [fields, setFields] = React.useState([]);
  const [headerProperties, setHeaderProperties] = React.useState({
    size: "medium",
    label: "",
    textAlign: "left",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "inherit"
  });
  const [footerProperties, setFooterProperties] = React.useState({
    label: "Submit",
    fontWeight:"400",
    backgroundColor: "",
  })
  const [menuSelect, setMenuSelect] = React.useState("builder");

  useEffect(() => {
    const loggedIn = utilities.isLoggedIn();
    if (loggedIn) { dispatch(loginUser()) }
  }, [])

  const addField = (fieldJson) => {
    const oldFields = fields;
    oldFields.push(fieldJson);
    setFields(oldFields);
  };
  window.onbeforeunload = (event) => {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = ""; // Legacy method for cross browser support
    }
    return ""; // Legacy method for cross browser support
  };
  console.log(fields, loggedIn, menuSelect);
  const fieldsDrawer = openFieldDrawer && (
    <FieldsDrawer addField={addField} closeDrawer={setOpenFieldDrawer} />
  );

  const FormSettingsMarkup = menuSelect === "settings" && <FormSettings />;

  const FormEditMarkup = (
    <Paper style={{ display: `${menuSelect === "settings" ? "none" : "block"}` }} className="form-builder-paper">
      <Grid
        container
        style={{ height: "100%" }}
        direction="column"
        justifyContent="center"
      >
        <FormHeader headerProperties={headerProperties} setHeaderProperties={setHeaderProperties} size={"small"} />
        <FormFields fields={fields} />
        <AddFieldButton openDrawer={setOpenFieldDrawer} />
        <FormSubmission footerProperties={footerProperties} setFooterProperties={setFooterProperties} />
      </Grid>
    </Paper>
  );

  return (
    <div>
      {fieldsDrawer}
      <Grid container direction="column">
        <Grid item>
          <FormNavigation setMenuSelect={setMenuSelect} />
        </Grid>
        <Grid item>{FormSettingsMarkup}</Grid>
        <Grid item className="form-builder">
          {FormEditMarkup}
        </Grid>
      </Grid>
    </div>
  );
}
