import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import loginReducer from "../features/login/loginSlice";
import accountReducer from "../features/account/accountSlice";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    counter: counterReducer,
    loggedIn: loginReducer,
  },
});
