import {
  FormControlLabel,
  FormGroup,
  TextField,
  Checkbox,
} from "@mui/material";
import React from "react";
import FieldSettingsDialog from "../../../Dialogs/FieldSettingsDialog";

export default function TextFieldSettingsDialog({
  close,
  _label = "",
  _halfSize = false,
  _required= false,
  addField,
}) {
  const [label, setLabel] = React.useState(_label);
  const [labelError, setLabelError] = React.useState(false);
  const [halfSize, setHalfSize] = React.useState(_halfSize);
  const [required, setRequired] = React.useState(_required);

  const handleChange = (event) => {
    setHalfSize(event.target.checked);
  };
  const handleRequired = (event) => {
    setRequired(event.target.checked)
  }
  const field = () => {
    return {
      name: label.replaceAll(" ", "_").toLowerCase(),
      label: label,
      type: "text",
      required: required,
      half_size: halfSize,
    };
  };
  const submit = () => {
    if (label) {
      const settings = field();
      addField(settings);
      return true;
    } else {
      setLabelError(true);
    }
  };
  return (
    <FieldSettingsDialog
      save={submit}
      closeDialog={close}
      title={"Simple Text Field"}
    >
      <form>
        <FormGroup>
          <TextField
            required
            error={labelError}
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            fullWidth
            margin="normal"
            name="label"
            label={"Field Label"}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={required}
                onChange={handleRequired}
                name="required"
              />
            }
            label="Required"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={halfSize}
                onChange={handleChange}
                name="checkbox"
              />
            }
            label="Half Size"
          />
        </FormGroup>
      </form>
    </FieldSettingsDialog>
  );
}
