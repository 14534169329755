import Cookies from "js-cookie"

const utilities = {
    isLoggedIn: () => {
        if(Cookies.get("JAMFREE")){
            return true;
        }
        else{
            return false;
        }
    }
}
export default utilities