import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function valuetext(value) {
  return `${value}`;
}

export default function DiscreteSlider({
  step,
  min,
  max,
  defaultValue,
  setValue,
  title,
}) {
  const classes = useStyles();
  const onChange = (value) => {
    setValue(value);
  };

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-small-steps" gutterBottom>
        {title}
      </Typography>
      <Slider
        value={defaultValue}
        getAriaValueText={valuetext}
        onChangeCommitted={(e, val) => onChange(val)}
        aria-labelledby="discrete-slider-small-steps"
        step={step}
        marks
        min={min}
        max={max}
        valueLabelDisplay="auto"
      />
    </div>
  );
}
