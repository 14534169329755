// import {
//   DataGrid,
//   GridRowId,
//   GridEditCellPropsParams,
// } from "@material-ui/data-grid";
import {
  DataGrid,
  GridCellEditCommitParams,
  GridRowId,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import styles from "../FormEntriesDashboard.module.css";
import EntriesToolbar from "./EntriesToolbar";
import DeleteModal from "./DeleteModal";
import formsApi from "../../../formsApi";
import { Entry } from "../FormEntriesDashboard";
import { useParams } from "react-router-dom";
import { Field } from "../../../../../components/Shared/FormConstructor/FormConstructor";

export interface Props {
  loading?: boolean;
  numberOfEntries?: number;
  setLoading?: any;
  columns?: Column[] | undefined;
  isSubmissionField: Function;
  formId: string;
  formFields: Array<Field>;
}

interface Column {
  field: string;
  flex?:number;
  editable: boolean;
  headerName: string;
  width?: number;
}
export interface Submission {
  [key: string]: string | number;
}

interface Update {
  submission: Submission;
  status?: string;
}
export interface Form {
  title: string;
  created_at: string;
  description?: string;
  fields: Array<Field>;
}
interface Params {
  id: string;
}
export default function Entries({ isSubmissionField, formId, columns, formFields }: Props) {
  const [numSelected, setNumSelected] = React.useState(0);
  const [rowSelected, setRowSelected] = React.useState<GridRowId[]>([]);
  const [verifyDelete, setVerifyDelete] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [entriesData, setEntriesData] = React.useState<Entry[] | []>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [form, setForm] = React.useState<Form | undefined>(undefined);
  const { id } = useParams<Params>();

  useEffect(() => {
    setLoading(true);
    formsApi.getFormEntries(id).then((res) => {
      if (res.status == 200) {
        setEntriesData(res.data.entries);
      }
    });
    setLoading(false);
  }, []);

  const onDelete = () => {
    setDeleteModal(true);
  };
  const commitUpdate = (entry: any, update: Update, field: string) => {
    let commit = true;
    console.log(entry, update, field)
    if (entry.submission[field] === update.submission[field]) {
      commit = false;
    }
    return commit;
  };
  const performDelete = () => {
    console.log("perform delete");
  };
  const handleSelectionChange = (newSelection: GridRowId[]) => {
    setNumSelected(newSelection.length);
    setRowSelected(newSelection);
  };

  const handleCellUpdateCommit = async (params: GridCellEditCommitParams) => {
    //let updates: { [key: string]: string|Number; } = {};
    let update: Update = { submission: {} };
    let value = params?.value;
    let id = params.id;
    let index = entriesData.findIndex((entry: any) => entry.id === id);
    let entry = entriesData[index];
    if (value && isSubmissionField(params.field)) {
      update.submission[params.field.toString()] = value.toString();
      console.log(commitUpdate(entry, update, params.field));
      let updateResponse = commitUpdate(entry, update, params.field)
        ? formsApi.updateEntry({ updates: update }, formId, id)
        : null;
      console.log(updateResponse)
    }
  };

  function addEntry(entry: Entry){
    setEntriesData((entriesData => [...entriesData, entry]));
  }
  function buildGridRow(entries: Entry[]) {
    return entries.map(({ submission, id, status, created_at}) => {
      return { id: id, ...submission, status: status, created_at: created_at };
    });
  }

  const EntriesToolbarMarkup = () => {
    return (
      <EntriesToolbar
        rowsSelected={rowSelected}
        numSelected={numSelected}
        loading={loading}
        setLoading={setLoading}
        onDelete={onDelete}
        formId={id}
        formFields={formFields}
        addEntry={addEntry}
      />
    );
  };

  const DeleteModalMarkup = deleteModal && (
    <DeleteModal
      onConfirm={performDelete}
      onClose={setDeleteModal}
      title={"Delete Entries"}
    >
      Are you sure you want to delete all {numSelected} entries?
    </DeleteModal>
  );
  const mockEntries: [Entry] = require("../mockEntries.json").entries;
    console.log(entriesData)
  return (
    <div className={styles.EntriesPaper}>
      {DeleteModalMarkup}
      <DataGrid
        columnBuffer={columns.length}
        loading={loading}
        showColumnRightBorder={true}
        onCellEditCommit={(params) => handleCellUpdateCommit(params)}
        classes={{ root: styles.EntriesGrid }}
        checkboxSelection
        disableSelectionOnClick
        rows={entriesData.length > 0 ? buildGridRow(entriesData) : []}
        pageSize={30}
        columns={entriesData.length > 0 ? columns : []}
        onSelectionModelChange={(newSelection) =>
          handleSelectionChange(newSelection)
        }
        components={{
          Toolbar: EntriesToolbarMarkup,
        }}
      />
    </div>
  );
}
