import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react'
import styles from "./style.module.css";

export default function Dropdown({ name, key, id, value, options, label, onChange, required }) {

  const renderOptions = (options) => {
    return options.map((option) => (
      <MenuItem key={option.key} value={option.key} data-testid="dropdown:option">
        {option.label}
      </MenuItem>
    ));
  }
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel variant="outlined" htmlFor={key}>{label}</InputLabel>
      <Select variant="outlined" value={value} label id={key} onChange={handleChange} required={required} name={name} >
        {renderOptions(options)}
      </Select>
    </FormControl>
  )
}
