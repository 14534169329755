import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Controller } from "react-hook-form";
import { Field } from "../FormConstructor";

const ReactHookTextField = ({
  name,
  label,
  control,
  required,
  kind,
}: Field) => {
  const labelId = `${name}`;
  const multiline = kind === "multiline";
  return (
    <Controller
      rules={{ required: `${label} is required.` }}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            variant="standard"
            {...field}
            type={kind}
            fullWidth
            required={required}
            label={label}
            name={name}
            helperText={error ? error.message : null}
            error={!!error}
            margin="normal"
            multiline={kind === "multiline"}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};
export default ReactHookTextField;
